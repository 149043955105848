import Script from 'next/script';
import React from 'react';
import { customJsonStringify } from '../../helpers/bazaarVoice';

const buildBreadcrumbsShema = (breadcrumbs, locale, domainName) => {
    const buildList = breadcrumbs.map((item, index) => {
        return ({
            '@type': 'ListItem',
            'position': index + 2,
            'name': item.label,
            'item': `${domainName}/${locale}${item.url}/`,
        });
    });

    const schema = {
        '@context': 'https://www.schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': [
            {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Home',
                'item': `${domainName}/${locale}/`,
            },
            ...buildList,
        ],
    };

    return schema;
};

export default (breadcrumbs, locale, domainName) => {
    const productschema = buildBreadcrumbsShema(breadcrumbs, locale, domainName);

    return (
        <Script
            strategy="afterInteractive"
            type="application/ld+json"
            key="seo-breadcrumbs"
            dangerouslySetInnerHTML={{ __html: customJsonStringify(productschema) }}
        />
    );
};
