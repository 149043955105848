import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

const GtmHead = (props) => {
    const { interval = 3.5 } = props;
    const scriptContent = `document.addEventListener('DOMContentLoaded', function() {
        var timer=${interval}
        setTimeout(initGTM, timer*1000);
      });
      document.addEventListener('scroll', initGTMOnEvent);
      document.addEventListener('mousemove', initGTMOnEvent);
      document.addEventListener('touchstart', initGTMOnEvent);
      function initGTMOnEvent(event) {
        initGTM();
        event.currentTarget.removeEventListener(event.type, initGTMOnEvent); 
      }
    function initGTM() {
        if (window.gtmDidInit) {
            return false;
        }  
        console.info("Mounting GTM")
        window.gtmDidInit = true ;
    var Optanon = {LoadBanner: function(){}};
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', 'GTM-N94XXFB');}`;

    return <script defer dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(scriptContent) }} />;
};

GtmHead.propTypes = {
    interval: PropTypes.number,
};
export default GtmHead;
