import { useState } from 'react';
import { customJsonStringify } from '../../helpers/bazaarVoice';


export const useLocalStorage = (key, initialValue) => {
    if (!process.browser) return [];
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            let data = [];
            const voteId = {};
            if (window.localStorage.getItem(key) === null || window.localStorage.getItem(key) === undefined) {
                voteId.reviewId = value;
                data.push(voteId);
            } else {
                data = JSON.parse(window.localStorage.getItem(key));
                voteId.reviewId = value;
                data.push(voteId);
            }
            const valueToStore = data instanceof Function ? data(storedValue) : data;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, customJsonStringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
};
