export const getAverageRating = (allRatings, productId) => {
    return Math.floor((allRatings[productId] || 0) * 10) / 10;
};

export const generateCallbackUrl = (domainName, locale) => {
    return `${domainName}/${locale.toLowerCase()}`;
};

export const loadCSwidget = () => {
};

export const customJsonStringify = (str) => JSON.stringify(str).replace(/([&"'<>])/g, (char) => {
    switch (char) {
        case '&':
            return '\u0026';
        case '<':
            return '\u003c';
        case '>':
            return '\u003e';
        case '"':
            return '\u0022';
        case "'":
            return '\u0027';
        default:
            return char;
    }
});
