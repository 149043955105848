import React from 'react';
import Script from 'next/script';
import DOMPurify from 'isomorphic-dompurify';
import { pageSchemaMarkup } from '../../../pageSchemaMarkup';
import { customJsonStringify } from '../../helpers/bazaarVoice';


const rebuildJsonSchema = (jsonLdCollection, url, domainName) => {
    const isValidCollection = jsonLdCollection
    && jsonLdCollection.items
    && jsonLdCollection.items.length > 0;

    const schemaItems = isValidCollection
        ? jsonLdCollection.items.map((item) => {
            const itemTemp = { ...item };

            if (
                itemTemp.json
          && itemTemp.json.mainEntityOfPage
          && itemTemp.json.mainEntityOfPage['@id']
            ) {
                itemTemp.json.mainEntityOfPage['@id'] = `${domainName}${url}`;
            }

            return itemTemp.json;
        })
        : [];

    return schemaItems;
};

export default (jsonLdCollection, url, domainName) => {
    const domainPlaceholder = '$ENV_DOMAIN_NAME$';

    const preparedSchema = rebuildJsonSchema(jsonLdCollection, url, domainName);
    const jsonSchemas = [...pageSchemaMarkup, ...preparedSchema];

    return jsonSchemas.map((data, index) => {
        return (
            <Script
                strategy="afterInteractive"
                key={index}
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(customJsonStringify(data).replace(domainPlaceholder, domainName)),
                }}
            />
        );
    });
};
