import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

// import { StickyBannerStyled } from './StickyBanner.style';

export default class StickyBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,

        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const footerHeight = document.getElementById('footer-div').scrollHeight;
        const documentHeight = document.documentElement.scrollHeight;
        this.setState({
            isVisible: !!(((window.scrollY > 30) && (window.scrollY + window.innerHeight) <= (documentHeight - footerHeight))),
        });
    };

    render() {
        const { isVisible } = this.state;
        const { stickyBanner } = this.props;
        return (
            <div className="stickyBanner">
                <div className={isVisible ? 'scrollButtonContainer' : 'stickybannerFixed'}>
                    <div className="web-links">
                        {stickyBanner[0].imageCollection.items.map((item, index) => (

                            <a href={item.link} key={index} target={item && item.target} aria-label={item.name} rel={item && item.target === '_blank' ? 'noopener noreferrer' : item.name}>
                                <Image
                                    src={item && item.image && item.image.url}
                                    alt={item.altText && item.altText}
                                    data-action-detail={`${item.gaEventLabel && item.gaEventLabel}_Sticky Button`}
                                    className={`${item.gaEventClass && item.gaEventClass} event_buy_now`}
                                    loading="eager"
                                    width="100"
                                    height="45"
                                />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
StickyBanner.propTypes = {
    stickyBanner: PropTypes.object,
};
