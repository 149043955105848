import React, { useState, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';
import Img from '../Img/Img';


const ExternalLink = (props) => {
    const {
        data,
        className,
        eventClass = 'event_outbound_link',
        children, isCurrent,
        setCurrentLocalization = () => { },
        toggleCountrySelector = () => { },
        privacyLink,
        en,
    } = props;
    const { title, target, url, image, ccpa, adChoices, privacyChoices } = data || {};
    const targetWindow = target || '_blank';
    const [isNoEnglish, setIsNoEnglish] = useState(false);
    const [lang, setLang] = useState('');
    const latamLocals = ['Argentina', 'Bolivia', 'América Central', 'Chile', 'Colombia', 'Ecuador', 'México', 'Perú', 'Venezuela'];

    // non-English text on country selector is programmatically identified in this component
    // If non-English text (eg. Spain / Espanol) comes from cf we handle it with handleLang() then
    // splitting the title and putting it into a returned span elem with corresponding lang attr
    const handleLang = (language) => {
        switch (language) {
            case 'Spain / España':
            case 'Central-America / América-Central':
            case 'Peru / Perú':
                return (
                    setIsNoEnglish(true),
                    setLang('es')
                );
            case 'Germany / Deutschland':
                return (
                    setIsNoEnglish(true),
                    setLang('de')
                );
            case 'Israel / ישראל':
                return (
                    setIsNoEnglish(true),
                    setLang('il')
                );
            default:
                return null;
        }
    };

    const handleCurrentLocal = (e) => {
        latamLocals.forEach(local => {
            if (e.target.innerText === local) {
                e.preventDefault();
                toggleCountrySelector();
                setCurrentLocalization(e.target.innerText);
            }
        });
        toggleCountrySelector();
    };

    useEffect(() => {
        handleLang(title);
    }, [title]);

    if (ccpa) {
        return (
            <span
                key="consentLink"
                dangerouslySetInnerHTML={{
                    __html:
                        DOMPurify.sanitize(`<a
                             class="external-link event_outbound_link"
                             href="javascript:void(0)"
                             onclick="window.Optanon.ToggleInfoDisplay();"
                             data-action-detail="Optanon.ToggleInfoDisplay"
                             rel="noopener noreferrer"
                        >
                             ${title}
                        </a>`),
                }}
            />
        );
    }

    const content = children || (
        <>
            {isNoEnglish ? `${title.split(' ')[0]} / ` : title || null}
            {image && (
                <>
                    <Img
                        contentfulImage={image.cfmedia}
                        alt={adChoices ? image.name : image.alt}
                    />
                    {privacyChoices && <Img contentfulImage={image.cfmedia} alt={image.name} />}
                </>
            )}
        </>
    );

    return (
        <a
            target={targetWindow}
            href={title === en ? privacyLink : url}
            className={`${className || ''}${adChoices ? 'ad-choices' : ''} external-link ${eventClass}`}
            data-action-detail={title === en ? privacyLink : url}
            rel="no follow noopener noreferrer"
            title={title}
            aria-current={isCurrent}
            aria-label={`Learn more about ${title}`}
            onClick={handleCurrentLocal}
        >
            {content}
            {isNoEnglish && <span lang={lang}>{title.split(' ')[2]}</span>}
        </a>
    );
};

export default ExternalLink;

ExternalLink.propTypes = {
    isCurrent: PropTypes.bool,
    children: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    eventClass: PropTypes.string,
    setCurrentLocalization: PropTypes.func,
    toggleCountrySelector: PropTypes.func,
    privacyLink: PropTypes.string,
    en: PropTypes.any,
};
